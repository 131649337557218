import React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import PreLoader from './components/PreLoader';
import Home from './components/Home';
import Order from './components/Order';
import PortfolioPage from './components/PortfolioPage';
import NotFound from './components/NotFound';

export default () => (
  <Layout>
    <PreLoader />
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/order' component={Order} />
      <Route exact path='/portfolio' component={PortfolioPage} />
      <Route path='*' component={NotFound} />
    </Switch>
  </Layout>
);