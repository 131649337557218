import { Message } from '../model/Message';

interface MessageDTO {
    name: string,
    email: string,
    message: string
}

export interface SendMessageResponseDTO {
    success: boolean;
};

export const SendMessageAPI = {
    sendMessage: async (message: Message): Promise<SendMessageResponseDTO> => {
        const url = `api/SendMessage`;

        const messageDTO: MessageDTO = {
            name: message.name,
            email: message.email,
            message: message.message
        };

        const response = await fetch(new Request(url, {
            method: "post",
            body: JSON.stringify(messageDTO),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }));

        console.log("response", response);

        const sendMessageResponse: SendMessageResponseDTO = await response.json();

        return sendMessageResponse;
    },

    // for testing purposes.
    sendMessageMock: async (message: Message): Promise<SendMessageResponseDTO> => {
        console.log(message.message);

        return { success: true };
    }
}