import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as MakeOrder from './MakeOrder';
import * as SendMessage from './SendMessage';
import { History } from 'history';
import { ApplicationState } from './../store';

export default function configureStore(history: History, initialState: ApplicationState) {
    const reducers = {
        makeOrder: MakeOrder.reducer,
        sendMessage: SendMessage.reducer
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // create the composing function for our middlewares
    const composeEnhancers = composeWithDevTools({});

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    return createStore(
        rootReducer,
        initialState,
        compose(
            composeEnhancers(
                applyMiddleware(
                    ...middleware
                )
            )
        )
    );
}
