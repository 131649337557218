import * as React from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import MakeOrder from './MakeOrder';
import ContactUs from './ContactUs';
import Footer from './Footer';

const Order = () => (
  <div>
    <Header isFixedMenu={true} />
    <MakeOrder />
    <ContactUs />
    <Footer />
  </div>
);

export default connect()(Order);