import * as React from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Title from './Title';
import AboutUs from './AboutUs';
import Prices from './Prices';
import Process from './Process';
import ContactUs from './ContactUs';
import Footer from './Footer';
import ScrollUp from './ScrollUp';

const Home = () => (
  <div>
    <Header isFixedMenu={false} />
    <Title />
    <AboutUs />
    <Prices />
    <Process />
    <ContactUs />
    <Footer />
    <ScrollUp />
  </div>
);

export default connect()(Home);