import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { Message } from '../model/Message';
import { SendMessageAPI, SendMessageResponseDTO } from '../api/SendMessageAPI';

export interface SendMessageState {
    message: Message | null;
    isLoading: boolean;
    isSendMessageSuccess: boolean;
}

const sendMessageRequestType = 'SEND_MESSAGE_REQUEST';
const sendMessageResponseType = 'SEND_MESSAGE_RESPONSE';

export interface SendMessageRequestAction { type: 'SEND_MESSAGE_REQUEST', message: Message }
export interface SendMessageResponseAction { type: 'SEND_MESSAGE_RESPONSE', success: boolean }

type KnownAction =
      SendMessageRequestAction
    | SendMessageResponseAction;

export const actionCreators = {
    sendMessage: (message: Message): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        if (getState().sendMessage.isLoading) {
            return;
        }

        dispatch(<SendMessageRequestAction>{ type: sendMessageRequestType, message: message });
        var sendMessageResponse: SendMessageResponseDTO = await SendMessageAPI.sendMessage(message);
        dispatch(<SendMessageResponseAction>{ type: sendMessageResponseType, success: sendMessageResponse.success });
    }
};

const initialState: SendMessageState = { message: null, isLoading: false, isSendMessageSuccess: false };

export const reducer: Reducer<SendMessageState> = (state: SendMessageState = initialState, incomingAction: Action) => {
    state = state || initialState;

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case sendMessageRequestType:
            return {
                ...state,
                message: action.message,
                isLoading: true,
                isSendMessageSuccess: false
            };

        case sendMessageResponseType:
            return {
                ...state,
                message: null,
                isLoading: false,
                isSendMessageSuccess: action.success
            };
    }

    return state;
};