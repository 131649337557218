import * as React from 'react';
import { connect } from 'react-redux';

const Footer = () => (
    <footer id="footer" className="footer">
        <div className="container text-center">
            <div className="row">
                <div className="col-sm-12">
                    <div className="copyright wow zoomIn" data-wow-duration="1s">
                        <p>Tillverkad av<a href="http://konsthet.se">konsthet.se</a>2022. Alla rättigheter reserverade.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default connect()(Footer);