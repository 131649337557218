import { Order } from '../model/Order';

export interface OrderResponseDTO {
    success: boolean;
    orderId: string;
};

export const MakeOrderAPI = {
    makeOrder: async (order: Order): Promise<OrderResponseDTO> => {
        const url = `api/MakeOrder`;

        let formData = new FormData();

        formData.append('name', order.name);
        formData.append('email', order.email);
        formData.append('phone', order.phone);
        formData.append('description', order.description);
        formData.append('photo', order.photo);

        const response = await fetch(new Request(url, {
            method: "post",
            body: formData
        }));

        console.log("response", response);

        const orderResponse: OrderResponseDTO = await response.json();

        return orderResponse;
    },

    // for testing purposes.
    makeOrderMock: async (order: Order): Promise<OrderResponseDTO> => {
        console.log(order.name);

        return { success: true, orderId: '123' };
    }
}