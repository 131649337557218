import * as React from 'react';
import { connect } from 'react-redux';

const Process = () => (
    <section id="process" className="portfolio">
        <div className="container process_content">
            <div className="row wow fadeIn single_process_row">
                <div className="col-md-1 col-md-offset-5 col-xs-3 col-process-image">
                    <img src="../images/1.png" alt="1" />
                </div>

                <div className="col-md-6 col-xs-9">
                    <div className="text-left">
                        <h4>Foto</h4>
                        <div>
                            Skicka oss minst en bild.
                            Låt oss veta vilken storlek och stil du vill ha.
                            Du är varmt välkommen att kontakta oss för att diskutera detaljerna.
                            Vi behöver endast 10% förskottsbetalning.
                        </div>
                    </div>
                </div>
            </div>

            <div className="row wow fadeIn single_process_row">
                <div className="col-md-6 col-xs-9">
                    <div className="text-right">
                        <h4>Skiss</h4>
                        <div>
                            Porträttmålare förbereder en skiss och vi skickar ett foto av det för att göra eventuella förändringar.
                            Det tar typiskt 2-3 dagar.
                        </div>
                    </div>
                </div>

                <div className="col-md-1 col-xs-3 col-process-image">
                    <img src="../images/2.png" alt="2" />
                </div>
            </div>

            <div className="row wow fadeIn single_process_row">
                <div className="col-md-1 col-md-offset-5 col-xs-3 col-process-image">
                    <img src="../images/3.png" alt="3" />
                </div>

                <div className="col-md-6 col-xs-9">
                    <div className="text-left">
                        <h4>Porträtt</h4>
                        <div>
                            Du är nöjd med skissen och målaren börjar fylla i detaljer.
                            Det tar vanligtvis 2 veckor, men beror mycket på antal personer, storlek och stil på porträtten.
                        </div>
                    </div>
                </div>
            </div>

            <div className="row wow fadeIn single_process_row">
                <div className="col-md-6 col-xs-9">
                    <div className="text-right">
                        <h4>Betalning</h4>
                        <div>
                            Vi skickar dig ett foto som visar slutporträttet.
                            Om du är nöjd med resultatet betalar du resterande 90% av priset.
                        </div>
                    </div>
                </div>

                <div className="col-md-1 col-xs-3 col-process-image">
                    <img src="../images/4.png" alt="4" />
                </div>
            </div>

            <div className="row wow fadeIn single_process_row">
                <div className="col-md-1 col-md-offset-5 col-xs-3 col-process-image">
                    <img src="../images/5.png" alt="5" />
                </div>

                <div className="col-md-6 col-xs-9">
                    <div className="text-left">
                        <h4>Leverans</h4>
                        <div>
                            Grattis! Ditt efterlängtade porträtt skickas till dig!
                            Beroende på din plats kan det ta ytterligare 2 veckor att levereras.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default connect()(Process);