import * as React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

class NotFound extends React.Component<RouteComponentProps<any>> {
    constructor(props: RouteComponentProps<any>) {
        super(props);
    };

    componentDidMount() {
        // This method runs when the component is first added to the page
        this.props.history.push("/NotFound");
        window.location.reload();
    };

    public render() {
        return (
          <div>
            <h1>Sorry, can’t find that.</h1>
          </div>
        );
    }
};

export default withRouter(NotFound);