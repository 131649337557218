import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { Order } from '../model/Order';
import { MakeOrderAPI, OrderResponseDTO } from '../api/MakeOrderAPI';

export interface MakeOrderState {
    order: Order | null;
    isLoading: boolean;
    isOrderSuccess: boolean;
    orderId: string;
}

const makeOrderRequestType = 'MAKE_ORDER_REQUEST';
const makeOrderResponseType = 'MAKE_ORDER_RESPONSE';

export interface MakeOrderRequestAction { type: 'MAKE_ORDER_REQUEST', order: Order }
export interface MakeOrderResponseAction { type: 'MAKE_ORDER_RESPONSE', success: boolean, orderId: string }

type KnownAction =
      MakeOrderRequestAction
    | MakeOrderResponseAction;

export const actionCreators = {
    makeOrder: (order: Order): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        if (getState().makeOrder.isLoading) {
            return;
        }

        dispatch(<MakeOrderRequestAction>{ type: makeOrderRequestType, order: order });
        var orderResponse: OrderResponseDTO = await MakeOrderAPI.makeOrder(order);
        dispatch(<MakeOrderResponseAction>{ type: makeOrderResponseType, success: orderResponse.success, orderId: orderResponse.orderId });
    }
};

const initialState: MakeOrderState = { order: null, isLoading: false, isOrderSuccess: false, orderId: '' };

export const reducer: Reducer<MakeOrderState> = (state: MakeOrderState = initialState, incomingAction: Action) => {
    state = state || initialState;

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case makeOrderRequestType:
            return {
                ...state,
                order: action.order,
                isLoading: true,
                isOrderSuccess: false,
                orderId: ''
            };

        case makeOrderResponseType:
            return {
                ...state,
                order: null,
                isLoading: false,
                isOrderSuccess: action.success,
                orderId: action.orderId
            };
    }

    return state;
};