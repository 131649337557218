import * as React from 'react';
import { connect } from 'react-redux';

const Prices = () => (
    <section id="prices" className="ourPakeg">
        <div className="container">
            <div className="main_pakeg_content">
                <div className="row">
                    <div className="head_title text-center">
                        <h4>Stilar och priser</h4>
                    </div>
                </div>

                <div itemScope itemType={"http://schema.org/Painting"} className="row wow rotateInDownRight row-price">
                    <div className="col-md-4 col-md-offset-1 col-price-image">
                        <img itemProp={"image"} src="../images/newsone.jpg" alt="Porträttmålningar med olja" />
                    </div>

                    <div className="col-md-6 col-md-offset-1">
                        <div className="single_pakeg_text">
                            <div className="pakeg_title">
                                <h4 itemProp={"material"}>Olja</h4>
                            </div>

                            <div>
                                Exakt pris beror på bland annat komplexitet i omgivningen och antal personer som ska målas.
                                Vänligen kontakta oss för att få ett prisförslag baserat på ditt foto.
                            </div>

                            <ul itemScope itemProp={"offers"} itemType={"https://schema.org/Offer"}>
                                <li itemProp={"lowPrice"}> 30 x 40 cm ..................................................... från 3,700 SEK </li>
                                <li> 40 x 50 cm ..................................................... från 3,900 SEK </li>
                                <li> 50 x 60 cm ..................................................... från 4,100 SEK </li>
                                <li itemProp={"highPrice"}> 60 x 90 cm ..................................................... från 4,700 SEK </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div itemScope itemType={"http://schema.org/Painting"} className="row wow rotateInDownLeft row-price">
                    <div className="col-md-4 col-md-offset-1 col-price-image col-price-image-top">
                        <img itemProp={"image"} src="../images/newstwo.jpg" alt="Porträttmålningar med kol och penna" />
                    </div>

                    <div className="col-md-6">
                        <div className="single_pakeg_text">
                            <div className="pakeg_title">
                                <h4 itemProp={"material"}>Kol och penna</h4>
                            </div>

                            <div>
                                Exakt pris beror på bland annat komplexitet i omgivningen och antal personer som ska målas.
                                Vänligen kontakta oss för att få ett prisförslag baserat på ditt foto.
                            </div>

                            <ul itemScope itemProp={"offers"} itemType={"https://schema.org/Offer"}>
                                <li itemProp={"lowPrice"}> 30 x 40 cm ..................................................... från 2,900 SEK </li>
                                <li> 40 x 50 cm ..................................................... från 3,100 SEK </li>
                                <li> 50 x 60 cm ..................................................... från 3,300 SEK </li>
                                <li itemProp={"highPrice"}> 60 x 90 cm ..................................................... från 3,700 SEK </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 col-md-offset-1 col-price-image col-price-image-bottom">
                        <img itemProp={"image"} src="../images/newstwo.jpg" alt="Porträttmålningar med kol och penna" />
                    </div>
                </div>

                <div itemScope itemType={"http://schema.org/Painting"} className="row wow rotateInDownRight row-price">
                    <div className="col-md-4 col-md-offset-1 col-price-image">
                        <img itemProp={"image"} src="../images/newsthree.jpg" alt="Porträttmålningar med akvarell och pastell" />
                    </div>

                    <div className="col-md-6 col-md-offset-1">
                        <div className="single_pakeg_text">
                            <div className="pakeg_title">
                                <h4 itemProp={"material"}>Akvarell och pastell</h4>
                            </div>

                            <div>
                                Exakt pris beror på bland annat komplexitet i omgivningen och antal personer som ska målas.
                                Vänligen kontakta oss för att få ett prisförslag baserat på ditt foto.
                            </div>

                            <ul itemScope itemProp={"offers"} itemType={"https://schema.org/Offer"}>
                                <li itemProp={"lowPrice"}> 30 x 40 cm ..................................................... från 3,500 SEK </li>
                                <li> 40 x 50 cm ..................................................... från 3,700 SEK </li>
                                <li> 50 x 60 cm ..................................................... från 3,900 SEK </li>
                                <li itemProp={"highPrice"}> 60 x 90 cm ..................................................... från 4,500 SEK </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default connect()(Prices);