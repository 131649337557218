import * as React from 'react';
import { connect } from 'react-redux';

const Title = () => (
    <section id="title" className="slider">
        <div className="slider_overlay">
            <div className="container">
                <div className="row">
                    <div className="main_slider text-center">
                        <div className="col-md-12">
                            <div itemScope itemType="https://schema.org/Brand" className="main_slider_content">
                                <h1 itemProp={"name"} className="main_slider_text">Konsthet</h1>
                                <p itemProp={"slogan"}>Gör din porträttbild till ett konstverk! En oförglömlig gåva!</p>
                                <div className="nav"><a href="#aboutus" className="btn btn-lg">Läs mer</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default connect()(Title);