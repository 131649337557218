import * as React from 'react';
import { Link } from 'react-router-dom';

interface HeaderProps {
    isFixedMenu: boolean
};

interface HeaderState {
    isScrolledDown: boolean
};

export default class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);
        this.scrollTop = this.scrollTop.bind(this);

        this.state = {
            isScrolledDown: false
        };
    };

    componentWillMount() {
        // This method runs when the component is first added to the page
        window.addEventListener('scroll', this.handleScroll);
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    };

    handleScroll(event: Event) {
		var top = this.scrollTop();
		var height = 300;

		if (top > height) {
            this.setState({ isScrolledDown: true });
		} else {
            this.setState({ isScrolledDown: false });
		}
    };

    scrollTop() {
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        //var scrollLeft = supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;
        var scrollTop = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

        return scrollTop;
    }

    public render() {
        return <header id="home" className={ this.state.isScrolledDown || this.props.isFixedMenu ? "navbar-fixed-top menu-scroll" : "navbar-fixed-top" }>
            <div className="header_top_menu clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-md-offset-2 col-sm-12 text-right">
                            <div className="call_us_text">
                                <a href="mailto:info@konsthet.se"><i className="fa fa-envelope"></i>info@konsthet.se</a>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-12 text-right">
                            <div className="call_us_text">
                                <a href="javascript:;"><i className="fa fa-phone"></i>079-3402549</a>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                            <div className="head_top_social text-right">
                                <a href="https://www.facebook.com/konsthet/" target="_blank"><i className="fa fa-facebook"></i></a>
                                <a href="https://twitter.com/konsthet" target="_blank"><i className="fa fa-twitter"></i></a>
                                <a href="https://www.linkedin.com/company/40714572" target="_blank"><i className="fa fa-linkedin"></i></a>
                                <a href="https://www.pinterest.se/konsthet/" target="_blank"><i className="fa fa-pinterest-p"></i></a>
                                <a href="https://www.youtube.com/channel/UCyz4uVxVwJh3Tr__w8niDHA?view_as=subscriber" target="_blank"><i className="fa fa-youtube"></i></a>
                                <a href="https://www.instagram.com/konsthet/" target="_blank"><i className="fa fa-camera"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main_menu_bg">
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-default">
                            <div className="container-fluid">
                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                        data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                    <a itemScope itemType={"https://schema.org/Brand"} className="navbar-brand our_logo" href="#"><img itemProp={"logo"} src="images/logo.png" alt="Konsthet logo" /></a>
                                </div>

                                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

                                    <ul className="nav navbar-nav navbar-right">
                                        <li><a href="#aboutus">Vad kan vi erbjuda</a></li>
                                        <li><a href="#prices">Stilar och priser</a></li>
                                        <li><a href="#process">Hur fungerar det</a></li>
                                        <li><Link to="/portfolio">Portfolio</Link></li>
                                        <li><a href="#contactus">Kontakta oss</a></li>
                                        <li><Link to="/order" className="booking">Beställa porträtt</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>;
    }
}