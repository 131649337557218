import * as React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Button } from 'react-bootstrap';
import { ApplicationState } from './../store/index';
import { actionCreators } from '../store/MakeOrder';

interface MakeOrderComponentProps {
    isLoading: boolean;
    isOrderSuccess: boolean;
    orderId: string;
};

type MakeOrderProps =
    MakeOrderComponentProps
    & typeof actionCreators;

interface MakeOrderState {
    name: string;
    email: string;
    phone: string;
    description: string;
    photoFileName: string;
    photoFile: File | null;
};

export class MakeOrder extends React.Component<MakeOrderProps, MakeOrderState> {
    ghostInputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    constructor(props: MakeOrderProps) {
        super(props);

        this.handleNameOnChange = this.handleNameOnChange.bind(this);
        this.handleEmailOnChange = this.handleEmailOnChange.bind(this);
        this.handlePhoneOnChange = this.handlePhoneOnChange.bind(this);
        this.handleDescriptionOnChange = this.handleDescriptionOnChange.bind(this);
        this.handlePhotoOnChange = this.handlePhotoOnChange.bind(this);
        this.handlePhotoOnClick = this.handlePhotoOnClick.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            name: '',
            email: '',
            phone: '',
            description: '',
            photoFileName: '',
            photoFile: null
        };
    };

    getValidationState() {
        if (this.state.name && this.state.email && this.state.phone && this.state.photoFile) {
            return "success";
        }
        else {
            return "error";
        }
    };

    handleSubmit(e: any) {
        e.preventDefault();

        if (this.getValidationState() === 'success') {
            this.props.makeOrder({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                description: this.state.description,
                photo: this.state.photoFile! // already validated for null
            });
        }
    };

    handleNameOnChange(e: any) {
        this.setState({ name: e.target.value });
    };

    handleEmailOnChange(e: any) {
        this.setState({ email: e.target.value });
    };

    handlePhoneOnChange(e: any) {
        this.setState({ phone: e.target.value });
    };

    handleDescriptionOnChange(e: any) {
        this.setState({ description: e.target.value });
    };

    handlePhotoOnChange(e: any) {
        const photoFileName: string = (e.target.value).split('\\').pop();
        let photoFile: File | null = null;

        if (e.target.files.length > 0) {
            photoFile = e.target.files[0];
        }

        this.setState({ photoFile: photoFile, photoFileName: photoFileName });
    };

    handlePhotoOnClick(e: any) {
        if (this.ghostInputRef.current != null) {
            this.ghostInputRef.current.click();
        }
    };

    public render() {
        return <section id="order" className="order">
            <div className="container">
                <div className="order_content">
                    <div className="row">
                        <div className="head_title text-center">
                            <h4>Beställning</h4>
                        </div>

                        <div className="alert alert-success" role="alert" style={{display: this.props.isOrderSuccess ? 'block': 'none'}}>
                            <h4 className="alert-heading">Grattis!</h4>
                            <p>Din beställningsnummer är: {this.props.orderId}. Vi kommer att kontakta dig inom kort.</p>
                        </div>

                        <Form onSubmit={(e) => this.handleSubmit(e) } style={{display: this.props.isOrderSuccess ? 'none': 'block', paddingTop: "50px"}}>
                            <div className="col-md-8 col-md-offset-2 text-center">
                                <div className="text-left">
                                    <FormGroup>
                                        <input type="text" value={this.state.name} onChange={this.handleNameOnChange} className="form-control" name="name" placeholder="Namn" required={true} />
                                    </FormGroup>

                                    <FormGroup>
                                        <input type="email" value={this.state.email} onChange={this.handleEmailOnChange} className="form-control" name="email" placeholder="E-post" required={true} />
                                    </FormGroup>

                                    <FormGroup>
                                        <input type="phone" value={this.state.phone} onChange={this.handlePhoneOnChange} className="form-control" name="phone" placeholder="Telefonnummer" required={true} />
                                    </FormGroup>

                                    <FormGroup>
                                        <textarea value={this.state.description} onChange={this.handleDescriptionOnChange} className="form-control" name="message" rows={5} placeholder="Meddelande"></textarea>
                                    </FormGroup>

                                    <FormGroup>
                                        <div className="input-group input-file">
                                            <input type="file" ref={this.ghostInputRef} className="input-ghost" onChange={this.handlePhotoOnChange} style={{ visibility: 'hidden', height:0 }} />
                                            <input type="text" value={this.state.photoFileName} className="form-control fake-file-input" onMouseDown={this.handlePhotoOnClick} placeholder="Bild att måla" required={true} />
                                            <span className="input-group-btn">
                                                <button className="btn btn-primary btn-choose" onClick={this.handlePhotoOnClick} style={{height: '34px', marginRight: '0px'}} type="button">Ladda upp bild</button>
                                            </span>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="text-center">
                                    <button value="Beställa" className="btn btn-lg btn-order" disabled={this.props.isLoading}>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {this.props.isLoading ? "Bearbetar..." : "Beställa" }
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </section>;
    }
}

const mapStateToProps = (state: ApplicationState): MakeOrderComponentProps => ({
    isLoading: state.makeOrder.isLoading,
    isOrderSuccess: state.makeOrder.isOrderSuccess,
    orderId: state.makeOrder.orderId
});

export default connect(
    mapStateToProps,
    actionCreators
)(MakeOrder);