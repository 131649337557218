import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from './../store/index';

interface PreLoaderProps {
    isLoading: boolean;
};

export class PreLoader extends React.Component<PreLoaderProps> {
    public render() {
        return <div className='preloader'>
            <div className='loaded'>&nbsp;</div>
        </div>;
    }
}

const mapStateToProps = (state: ApplicationState): PreLoaderProps => ({
    isLoading: state.makeOrder.isLoading
});

export default connect(
    mapStateToProps
)(PreLoader);