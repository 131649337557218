import * as React from 'react';
import { connect } from 'react-redux';

const Portfolio = () => (
    <section id="portfolio" className="port">
        <div className="container">
            <div className="row">
                <img src="images/portfolio10.jpg" alt="" className="port_image" />
            </div>

            <div className="row">
                <img src="images/portfolio9.jpg" alt="" className="port_image" />
            </div>

            <div className="row">
                <img src="images/portfolio11.jpg" alt="" className="port_image" />
            </div>

            <div className="row">
                <img src="images/portfolio12.jpg" alt="" className="port_image" />
            </div>

            <div className="row">
                <img src="images/portfolio1.jpg" alt="" className="port_image" />
            </div>

            <div className="row">
                <img src="images/portfolio2.jpg" alt="" className="port_image" />
            </div>

            <div className="row">
                <img src="images/portfolio3.jpg" alt="" className="port_image" />
            </div>

            <div className="row">
                <img src="images/portfolio4.jpg" alt="" className="port_image" />
            </div>

            <div className="row">
                <img src="images/portfolio5.jpg" alt="" className="port_image" />
            </div>

            <div className="row">
                <img src="images/portfolio6.jpg" alt="" className="port_image" />
            </div>

            <div className="row">
                <img src="images/portfolio7.jpg" alt="" className="port_image" />
            </div>

            <div className="row">
                <img src="images/portfolio8.jpg" alt="" className="port_image" />
            </div>
        </div>
    </section>
);

export default connect()(Portfolio);