import * as React from 'react';
import { connect } from 'react-redux';

const AboutUs = () => (
    <section id="aboutus" className="abouts">
        <div className="container">
            <div className="row">
                <div className="abouts_content">
                    <div className="col-md-6">
                        <div className="single_abouts_text text-center wow slideInLeft" data-wow-duration="1s">
                            <img src="images/ab.jpg" alt="" />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="single_abouts_text wow slideInRight" data-wow-duration="1s">
                            <h4>Porträttmålningar</h4>
                            <h3>Individualitet i fokus</h3>

                            <p>
                                Vi tycker att det finns något magiskt när erfarna målare skapar ett porträtt.
                                Konstnären måste märka, förstå och bevara unika egenskaper av person han målar.
                                Man borde kunna se tankar, drömmar och värderingar på ansiktet som gör var och en av oss unika.
                                Exakt detta, enligt vår mening, gör en skillnad mellan bra och underbar porträtt. Det är det vi försöker att fokusera på i första hand.
                            </p>

                            <p>
                                Vi målar mest porträtt med olja, akvarel, pastell, blyertspenna och kol. Var och en av dessa medier har sina egna fördelar och nyanser.
                                Oljeporträts största fördel är färgdjup och flexibilitet. Oljemålning tenderar också att leva länge utan att förlora friskhet.
                                Blyertspenna är bra för detaljerade renderingar, men när det gäller uttrycksfulla ritningar är kol ofta ett bättre val.
                                Pastell erbjuder hög uttrycklighet och ljusstyrka.
                            </p>

                            <p>
                                Vänligen kontakta oss för att diskutera det bästa alternativet för dig.
                            </p>

                            <div className="nav"><a href="#prices" className="btn btn-primary">Stilar och priser</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default connect()(AboutUs);