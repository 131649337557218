import * as React from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Portfolio from './Portfolio';
import ContactUs from './ContactUs';
import Footer from './Footer';

const PortfolioPage = () => (
  <div>
    <Header isFixedMenu={true} />
    <Portfolio />
    <ContactUs />
    <Footer />
  </div>
);

export default connect()(PortfolioPage);