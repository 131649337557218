import * as React from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ApplicationState } from './../store/index';
import { actionCreators } from '../store/SendMessage';

interface SendMessageComponentProps {
    isLoading: boolean;
    isSendMessageSuccess: boolean;
};

type SendMessageProps =
    SendMessageComponentProps
    & typeof actionCreators;

interface ContactUsState {
    name: string;
    email: string;
    message: string;
};

export class ContactUs extends React.Component<SendMessageProps, ContactUsState> {
    constructor(props: SendMessageProps) {
        super(props);

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            name: '',
            email: '',
            message: ''
        };
    };

    handleNameChange(e: any) {
        this.setState({ name: e.target.value });
    }

    handleEmailChange(e: any) {
        this.setState({ email: e.target.value });
    }

    handleMessageChange(e: any) {
        this.setState({ message: e.target.value });
    }

    handleSubmit(e: any) {
        e.preventDefault();

        if (this.getValidationState() === 'success') {
            this.props.sendMessage({
                name: this.state.name,
                email: this.state.email,
                message: this.state.message,
            });

            this.setState({
                name: '',
                email: '',
                message: ''
            });
        }
    };

    getValidationState() {
        if (this.state.name && this.state.email && this.state.message) {
            return "success";
        }
        else {
            return "error";
        }
    };

    public render() {
        return <section id="contactus" className="footer_widget">
            <div className="container">
                <div className="row">
                    <div itemScope itemType={"https://schema.org/ContactPoint"} className="footer_widget_content text-center">
                        <div className="col-md-4">
                            <div className="single_widget wow fadeIn" data-wow-duration="2s">
                                <h3>Kontakt</h3>

                                <div className="single_widget_info">
                                    <p>
                                        <span className="phone_email">Telefon: <em itemProp={"telephone"}>079-3402549</em></span>
                                        <span  className="phone_email">E-post: <a href="mailto:info@konsthet.se"><em itemProp={"email"}>info@konsthet.se</em></a></span>
                                    </p>
                                </div>

                                <div className="footer_socail_icon">
                                    <a href="https://www.facebook.com/konsthet/" target="_blank"><i className="fa fa-facebook"></i></a>
                                    <a href="https://twitter.com/konsthet" target="_blank"><i className="fa fa-twitter"></i></a>
                                    <a href="https://www.linkedin.com/company/40714572" target="_blank"><i className="fa fa-linkedin"></i></a>
                                    <a href="https://www.pinterest.se/konsthet/" target="_blank"><i className="fa fa-pinterest-p"></i></a>
                                    <a href="https://www.youtube.com/channel/UCyz4uVxVwJh3Tr__w8niDHA?view_as=subscriber" target="_blank"><i className="fa fa-youtube"></i></a>
                                    <a href="https://www.instagram.com/konsthet/" target="_blank"><i className="fa fa-camera"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="single_widget wow fadeIn" data-wow-duration="4s">
                                <h3>Telefontider</h3>

                                <div itemScope itemProp={"hoursAvailable"} itemType={"https://schema.org/OpeningHoursSpecification"} className="single_widget_info">
                                    <p>
                                        <span itemProp={"dayOfWeek"} className="date_day">Måndag - Fredag</span>
                                        <span><em itemProp={"opens"}>08.00</em> - <em itemProp={"closes"}>16.00</em></span>

                                        <span itemProp={"dayOfWeek"} className="date_day">Lördag och Söndag</span>
                                        <span><em itemProp={"opens"}>10.00</em> - <em itemProp={"closes"}>16.00</em></span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="single_widget wow fadeIn" data-wow-duration="5s">
                                <h3>Skicka meddelande</h3>

                                <div className="single_widget_form text-left">
                                    <Form onSubmit={(e) => this.handleSubmit(e) } action="#" id="formid">
                                        <FormGroup>
                                            <input type="text" value={this.state.name} onChange={this.handleNameChange} className="form-control" name="name" placeholder="Namn" required={true} />
                                        </FormGroup>

                                        <FormGroup>
                                            <input type="email" value={this.state.email} onChange={this.handleEmailChange} className="form-control" name="email" placeholder="E-post" required={true} />
                                        </FormGroup>

                                        <FormGroup>
                                            <textarea className="form-control" value={this.state.message} onChange={this.handleMessageChange} name="message" rows={3} placeholder="Meddelande" required={true} />
                                        </FormGroup>

                                        <div className="alert alert-success" role="alert" style={{ display: this.props.isSendMessageSuccess ? 'block': 'none' }}>
                                            Tack för din meddelande!
                                        </div>

                                        <input type="submit" value="Skicka" className="btn btn-primary" disabled={this.props.isLoading} />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }
}

const mapStateToProps = (state: ApplicationState): SendMessageComponentProps => ({
    isLoading: state.sendMessage.isLoading,
    isSendMessageSuccess: state.sendMessage.isSendMessageSuccess
});

export default connect(
    mapStateToProps,
    actionCreators
)(ContactUs);